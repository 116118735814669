@tailwind base;
@tailwind components;
@tailwind utilities; 

@layer base {
  @font-face {
    font-family: Aeonik;
    font-weight: 700;
    src: url("./Assets/Font/Aeonik/Aeonik-Bold.otf") format("OpenType");
  }
  @font-face {
    font-family: Aeonik;
    font-weight: 600;
    src: url("./Assets/Font/Aeonik/Aeonik-Black.otf") format("OpenType");
  }
  @font-face {
    font-family: Aeonik;
    font-weight: 500;
    src: url("./Assets/Font/Aeonik/Aeonik-Medium.otf") format("OpenType");
  }
  @font-face {
    font-family: Aeonik;
    font-weight: 450;
    src: url("./Assets/Font/Aeonik/Aeonik-Regular.otf") format("OpenType");
  }
  @font-face {
    font-family: Aeonik;
    font-weight: 400;
    src: url("./Assets/Font/Aeonik/Aeonik-Light.otf") format("OpenType");
  }
  @font-face {
    font-family: Aeonik;
    font-weight: 300;
    src: url("./Assets/Font/Aeonik/Aeonik-Thin.otf") format("OpenType");
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}


body {
  font-family: "Aeonik", sans-serif;
}
.section {
  display: flex;
  animation: swipe var(--speed) linear infinite backwards;
}

.section2 {
  display: flex;
  animation: swipe2 var(--speed) linear infinite backwards;
  animation-delay: 15000ms;
}

@keyframes swipe {
  /* 0% {
    transform: translate(3009.95px);
  } */
  0% {
    transform: translate(0px);
  }

  100% {
    transform: translate(-100vw);
  }
  /* 100% {
    transform: translate(-3009.95px);
  } */
  /* 0% {
    transform: translate(1159.95px);
  }

  100% {
    transform: translate(-5159.95px);
  } */
}
@keyframes swipe2 {
  0% {
    transform: translate(3159.95px);
  }

  100% {
    transform: translate(-3159.95px);
  }
}
